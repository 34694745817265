import React, { useState } from "react";
import Style from "./Navbar.css";
import Toggler from "./home/Toggler";
import { Link, useLocation } from "react-router-dom";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { info } from "../info/Info";
import logo from "../img/logo.webp";

const links = [
  {
    name: "Home",
    to: "/",
    active: "home",
  },
  {
    name: "About Me",
    to: "/about",
    active: "about",
  },
  {
    //name: info.initials,
    type: "initials",
    to: "/",
    active: "home",
    img: logo,
  },
  {
    name: "Portfolio",
    to: "/portfolio",
    active: "portfolio",
  },
  {
    name: "Blog",
    to: "/blog",
    active: "blog",
  },
];
export default function Navbar({ darkMode, handleClick }) {
  const location = useLocation();
  const [active, setActive] = useState(
    location.pathname === "/"
      ? "home"
      : location.pathname.slice(1, location.pathname.length)
  );
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const hamburgerColor = darkMode ? "white" : "black";
  const panelColor = darkMode ? "white" : "black";
  const menuLinkColor = darkMode
    ? isMobile
      ? "black"
      : "white"
    : isMobile
    ? "white"
    : "black";

  return (
    <Box component={"nav"} width={"100%"}>
      {isMobile && (
        <IconButton
          id="hamburgerTop"
          style={{
            color: hamburgerColor,
            fontSize: "3rem",
          }}
          onClick={handleMenuToggle}
        >
          {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      )}
      <Box
        component={"ul"}
        display={!isMobile || isMenuOpen ? "flex" : "none"}
        flexDirection={isMobile ? "column" : "row"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={{ xs: "2rem", md: "8rem" }}
        fontSize={"1rem"}
        style={{
          position: isMobile ? "absolute" : "static",
          top: isMobile ? "64px" : "0",
          left: isMobile && isMenuOpen ? "0" : "auto",
          right: isMobile && isMenuOpen ? "0" : "auto",
          bottom: isMobile && isMenuOpen ? "0" : "auto",
          backgroundColor: isMobile && isMenuOpen ? panelColor : "transparent",
          boxShadow:
            isMobile && isMenuOpen ? "0 0 10px rgba(0, 0, 0, 0.25)" : "none",
          width: isMobile && isMenuOpen ? "100vw" : "auto",
          height: isMobile && isMenuOpen ? "100vh" : "auto",
          overflow: isMobile && isMenuOpen ? "hidden" : "visible",
          zIndex: isMobile && isMenuOpen ? "1" : "auto",
          opacity: "95%",
        }}
      >
        {links.map((link, index) => (
          <Box
            key={index}
            component={"li"}
            className={link.active === active && !link.type && Style.active}
            sx={{ borderImageSource: info.gradient }}
          >
            <Link
              to={link.to}
              onClick={() => {
                setActive(link.active);
                if (isMobile) {
                  setIsMenuOpen(false);
                }
              }}
              className={Style.link}
            >
              {!link.type && (
                <p
                  style={{
                    padding: "0.5rem 0",
                    color: menuLinkColor,
                  }}
                >
                  {link.name}
                </p>
              )}
              {link.type && link.name && <h1>{link.name}</h1>}
              {!link.name && (
                <img
                  style={{ width: 200 + "px" }}
                  src={link.img}
                  alt="project"
                />
              )}
            </Link>
          </Box>
        ))}
      </Box>
      <div
        style={{
          display: isMenuOpen ? "none" : "flex",
          justifyContent: "right",
        }}
      >
        <Toggler darkMode={darkMode} handleClick={handleClick} />
      </div>
    </Box>
  );
}
