import self from "../img/self.webp";
import mock1 from "../img/mock1.webp";
import mock2 from "../img/mock2.webp";
import mock3 from "../img/mock3.webp";
import mock4 from "../img/mock4.webp";
import mock5 from "../img/mock5.webp";

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];

export const info = {
  firstName: "Ulaş",
  lastName: "Müezzinoğlu",
  initials: "Java",
  position: "a Senior Backend Developer",
  selfPortrait: self,
  gradient: `-webkit-linear-gradient(135deg, ${colors})`,
  baseColor: colors[0],
  miniBio: [
    {
      emoji: "☕",
      text: "fueled by coffee",
    },
    {
      emoji: "🌎",
      text: "based in the Samsun",
    },
    {
      emoji: "💼",
      text: "Backend Software Developer at UpVisit",
      url: "https://upvisit.io",
    },
    {
      emoji: "📧",
      text: "iletisim@ulasmuezzinoglu.net",
    },
  ],
  socials: [
    {
      link: "https://stackoverflow.com/users/16507028/",
      icon: "fa fa-stack-overflow",
      label: "Stack overflow",
    },
    {
      link: "https://instagram.com/he.justulas",
      icon: "fa fa-instagram",
      label: "instagram",
    },
    {
      link: "https://github.com/UlasMuezzinoglu",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "https://www.linkedin.com/in/ulasmuezzinoglu/",
      icon: "fa fa-linkedin",
      label: "linkedin",
    },
  ],
  bio: "Hello there! I am Ulaş. I'm a Backend Software Developer at UpVisit. I studied Computer Science at Ordu University and graduated at the top of my Faculty. (3,62/4) ",
  skills: {
    proficientWith: [
      "Java",
      "C#",
      "Node.js",
      "Javascript",
      "Typescript",
      "Spring",
      "SQL",
      "NO-SQL",
      "React",
      "Angular",
      "Docker",
      "AWS",
      "RabbitMQ",
      "Google Pub/Sub",
    ],
    exposedTo: [],
  },
  hobbies: [
    {
      label: "reading",
      emoji: "📖",
    },
    {
      label: "theater",
      emoji: "🎭",
    },
    {
      label: "movies",
      emoji: "🎥",
    },
    {
      label: "cooking",
      emoji: "🌶",
    },
  ],
  portfolio: [
    {
      title: "Fitty",
      live: "https://fittygym.com/",
      image: mock1,
      description:
        "Fitty is a mobile application that allows you to pay only the amount of time you spend in sports with per minute pricing in all contracted gyms",
    },
    {
      title: "Alıcı Bul",
      image: mock2,
      description:
        "next generation vehicle sales platform. As a feature it has an artificial intelligence that can erase the backgrounds of vehicles and place them in studios / platforms",
    },
    {
      title: "Car Studio AI",
      live: "https://carstudio.ai",
      image: mock3,
      description:
        "Carstudio is an artificial intelligence algorithm that visually places the vehicle photos taken by people in to the automotive studio. Currently,they are selling this service to 11 countries",
    },
    {
      title: "Defimec",
      image: mock4,
      live: "https://defimec.com/",
      description:
        "Defimec Individual tokenization platform is the organization that tokenizes individuals and assets and organizes their sale. It can also exchange and sell on the deximec exchange.",
    },
    {
      title: "Uçuşumu Bul App",
      image: mock5,
      description:
        "Uçuşumu Bul is a web application that scans the internet for you and notifies you when flightson the route you have specified fall below the price you have set",
    },
  ],
};
