import Typography from "@mui/material/Typography";
import IconLink from "./IconLink";

function ModalItem(props) {
  const { title, image, live, description } = props;
  return (
    <div>
      <h1>What is {title} ?</h1>
      <img src={image} alt="project" />
      <Typography id="modal-modal-title" variant="h6" component="h3">
        {description}
      </Typography>
      <br></br>
      <br></br>
      <div hidden={live ? false : true}>
        <Typography id="modal-modal-title" variant="h6" component="h3">
          <IconLink link={live} title={"Live Demo"} icon={"fa fa-safari"} />
        </Typography>
      </div>
    </div>
  );
}

export default ModalItem;
