import React from "react";
import { Box } from "@mui/material";

function EmojiBullet(props) {
  const { emoji, text, isMailLink, url } = props;

  return (
    <Box
      component={"li"}
      fontSize={"1rem"}
      lineHeight={1.5}
      style={{ cursor: "default" }}
    >
      <Box
        component={"span"}
        aria-label="cheese"
        role="img"
        mr={{ xs: "0.5rem", md: "1rem" }}
        fontSize={"1.5rem"}
      >
        {emoji}
      </Box>
      <span>{!isMailLink && !url ? text : ""}</span>
      <span>{isMailLink ? <a href={`mailto:${text}`}>{text}</a> : ""}</span>
      <span>
        {url ? (
          // eslint-disable-next-line
          <a href={`${url}`} target="_blank">
            {text}
          </a>
        ) : (
          ""
        )}
      </span>
    </Box>
  );
}

export default EmojiBullet;
